import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const EggWhispererLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Aimee"
    institution="Dr. Aimee"
    referralCode="EGGWHISPERER"
    physicianURL="https://www.draimee.org"
  />
)

export default EggWhispererLandingPage
